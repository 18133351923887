<template>
  <div data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="60">
    <HeaderPage />
    <slot />
    <a href="#" @click="topFunction" class="back-to-top-btn btn btn-dark" id="back-to-top"><i class="mdi mdi-chevron-up"></i></a>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import HeaderPage from '@/components/HeaderPage.vue'
import "@/assets/scss/style.scss"

const windowScroll = () => {
  const navbar = document.getElementById("navbar-sticky");
  if (navbar) {
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }
  }
}

const scrollFunction = () => {
  const mybutton = document.getElementById("back-to-top");
  if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}

const topFunction = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

const fadeIn = () => {
  const fade = document.getElementById("error-msg");
  let opacity = 0;
  const intervalID = setInterval(() => {
    if (opacity < 1) {
      opacity = opacity + 0.5;
      fade.style.opacity = opacity;
    } else {
      clearInterval(intervalID);
    }
  }, 200);
}

onMounted(() => {
  /* Theme Name: Dojek - Responsive bootsrap 4 Landing Page Template
    Author: Coderthemes
    Version: 1.0.0
    Created: May 2018
    File Description: Main Js file of the template
  */

  window.addEventListener("scroll", (ev) => {
    ev.preventDefault();
    windowScroll();
  });

  window.onscroll = function () {
    scrollFunction();
  };

  // Render bootstrap.bundle.min.js after page render
  const script = document.createElement('script');
  script.src = "@/assets/js/bootstrap.bundle.min.js";
  script.async = true;
  document.body.appendChild(script);
});

</script>
